// Account Css
.rs-my-account{
    .title{
        font-size: 42px;
        line-height: 52px;
        font-weight: 600;
        color: $titleColor;
        margin: 0;
    }
    .rs-login{
        border: 1px solid rgba(54,54,54,.1);
        padding: 20px;
        text-align: left;
        border-radius: 5px;
        .form-group{
            .form-part {
                label{
                    display: block;
                    font-size: 15px;
                    line-height: 30px;
                    font-weight: 600;
                    color: $bodyColor;
                    span{
                        color: $primaryColor;
                    }
                }
                input{
                    width: 100%;
                    margin: 0;
                    outline: 0;
                    line-height: normal;
                    border: 1px solid rgba(54,54,54,.1);
                    height: 45px;
                }
            }
            .btns-part {
                button {
                    margin-right: 15px;
                }
                span {
                    margin-left: 5px;
                }
            }
        }
        .last-password{
            a{
                font-size: 15px;
                line-height: 26px;
                font-weight: 400;
                color: $primaryColor;
            }
        }
        p{
            font-size: 15px;
            line-height: 26px;
            font-weight: 400;
            color: $bodyColor;
            margin-bottom: 14px;
            a{  
                font-size: 15px;
                line-height: 26px;
                font-weight: 400;
                color: $primaryColor;
            }
        }
    }
}

// Checkout Css
.rs-checkout {
    .checkout-title {
        margin-bottom: 30px;
        h3 {
            font-size: 26px;
            margin: 0;
        }
    }
    .coupon-toggle {
        .accordion__item {
            border-top: 3px solid $primaryColor;
            .accordion__heading {
                .accordion__button {
                    background: rgba(0,0,0,.03);
                    padding: .75rem 1.25rem;
                    .card-title {
                        margin: 0;
                        display: flex;
                        align-items: center;
                        span {
                            margin-right: 10px;
                            i {
                                margin-right: 10px;
                            }
                        }
                        .accordion-toggle {
                            background: unset;
                            border: none;
                            color: $primaryColor;
                            transition: $transition;
                            outline: none;
                            cursor: pointer;
                            &:hover {
                                color: $primaryColor;
                            }
                        }
                    }
                    &::before {
                        display: none;
                    }
                }
            }
            .accordion__panel {
                padding: 0;
                .card-body {
                    border: 1px solid #d3ced2;
                    padding: 20px 20px 50px 20px;
                    margin-top: 2em;
                    text-align: left;
                    .coupon-code-input {
                        width: 47%;
                        float: left;
                        margin-right: 50px;
                        input {
                            width: 100%;
                            height: 45px;
                            outline: none;
                            padding: 10px 18px;
                            color: $bodyColor;
                            border: 1px solid rgba(54, 54, 54, 0.1);
                        }
                    }
                }
            }
        }
    }
    .full-grid { 
        margin-top: 25px;   
        .form-content-box {
            margin-bottom: 50px;
            .form-group {
                label {
                    line-height: 2;
                    display: block;
                    margin-bottom: 5px;
                    font-weight: 600;
                }
                select {
                    color: #666666;
                    opacity: .5;
                    padding-left: 8px;
                    padding-right: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    border: 1px solid #ccc;
                    border-radius: 0;
                    height: 45px;
                    line-height: 45px;
                    cursor: pointer;
                    width: 100%;
                    outline: none;
                }
                textarea {
                    height: 4em;
                    line-height: 1.5;
                    display: block;
                    box-shadow: none;
                    width: 100%;
                    border: 1px solid rgba(54, 54, 54, 0.1);
                    padding: 10px 18px;
                    margin: 0;
                    outline: none;
                }
                .form-control-mod {
                    border: 1px solid rgba(54, 54, 54, 0.1);
                    height: 45px;
                    padding: 10px 18px;
                    width: 100%;
                    margin: 0;
                    outline: none;
                    line-height: normal;
                    border-radius: unset;
                    &.margin-bottom {
                        margin-bottom: 10px !important;
                    }
                }
            }
        }
        .ordered-product {
            table {
                width: 100%;
                tr {
                    th {
                        border: 1px solid #ccc;
                        padding: 9px 12px;
                    }
                    td {
                        border: 1px solid #ccc;
                        padding: 6px 12px;
                    }
                }
            }
        }

        .payment-method {
            .top-area {
                border-bottom: 1px solid #d3ced2;
                .payment-co {
                    margin-bottom: 20px;
                    span {
                        font-weight: 600;
                        margin-right: 10px;
                    }
                }
                .p-msg {
                    position: relative;
                    box-sizing: border-box;
                    width: 100%;
                    padding: 1em;
                    margin: 1em 0 2em;
                    font-size: .92em;
                    border-radius: 2px;
                    line-height: 1.5;
                    background-color: #f9f9f9;
                    &:before {
                        content: '';
                        display: block;
                        border: 1em solid #f9f9f9;
                        border-right-color: transparent;
                        border-left-color: transparent;
                        border-top-color: transparent;
                        position: absolute;
                        top: -.75em;
                        left: 0;
                        margin: -1em 0 0 2em;
                    }
                }
            }
        }
    }
}

// Cart Css
.rs-cart {
    .cart-wrap {
        table.cart-table {
            border: 1px solid rgba(0,0,0,.1);
            width: 100%;
            border-collapse: collapse;
            td, th {
                padding: 25px;
                text-align: center;
                border: 1px solid #ccc;
            }
            th {
                border: none;
                font-size: 18px;
                padding: 25px;
                text-align: center;
                vertical-align: middle;
                font-weight: 700;
            }
            td {
                border-bottom: none;
                border-left: none;
                border-right: none;
                border-top: 1px solid #e6e6e6;
            }
            .product-remove {
                a {
                    margin: 0 auto;
                    color: $titleColor;
                    display: block;
                    border-radius: 100%;
                    border: 1px solid #ddd;
                    font-size: 15px;
                    font-weight: 400;
                    height: 20px;
                    width: 20px;
                    line-height: 19px;
                    text-align: center;
                    &:hover {
                        background: #ff0000;
                        color: $whiteColor;
                    }
                }
            }
            .product-thumbnail {
                min-width: 32px;
                a {
                    img {
                        width: 80px;
                        height: auto;
                    }
                }
            }
            .product-name {
                a {
                    color: $bodyColor;
                    font-weight: 700;
                    &:hover {
                        color: $primaryColor;
                    }
                }
            }
            .product-price {
                font-weight: 700;
            }
            .product-quantity {
                input {
                    border: 1px solid #e6e6e6;
                    border-radius: 4px;
                    padding: 0 0 0 10px;
                    max-width: 65px;
                    margin: 0 auto;
                    outline: none;
                }
            }
            .action {
                .coupon {
                    input {
                        box-sizing: border-box;
                        border: 1px solid #d3ced2;
                        padding: 6px 6px 5px;
                        margin: 0 4px 0 0;
                        outline: none;
                        width: 320px;
                        border-radius: 4px;
                        height: 45px;
                        margin-right: 20px;
                        text-align: left;
                        padding-left: 22px;
                    }
                }
            }
        }
    }
    .cart-collaterals {
        width: 100%;
        overflow: hidden;
        .cart-totals {
            float: right;
            width: 48%;
            .title {
                font-weight: 700;
                color: $bodyColor;
                text-transform: capitalize;
            }
            table.cart-total-table {
                text-align: left;
                width: 100%;
                border-collapse: collapse;
                border-radius: 5px;
                tr {
                    border-top: 1px solid rgba(0,0,0,.1);
                    th {
                        font-size: 18px;
                        font-weight: 700;
                    }
                    td,th {
                        padding: 30px 10px;
                    }
                }
            }
        }
    }
}

.rs-shop-part{
    .woocommerce-result-count{
        font-size: 15px;
        line-height: 26px;
        color: $bodyColor;
        font-weight: 400;
        margin: 0;
    }
    .from-control{
        float: right;
        font-size: 15px;
        color: $bodyColor;
        font-weight: 400;
        vertical-align: top;
        padding: 10px;
        border: 1px solid #ced4da;
        border-radius: 5px;
    }
    .product-list{
        .image-product{
            position: relative;
            .overlay{
                i{
                    position: absolute;
                    bottom: 150px;
                    right: 13px;
                    background: transparent;
                    opacity: 0;
                    visibility: hidden;
                    transition: 0.4s;
                    &:before{
                        font-size: 20px;
                        line-height: 45px;
                        color: $whiteColor;
                        font-weight: 400;
                        background: $primaryColor;
                        border: none ;
                        padding: 12px;
                        height: 45px;
                        width: 45px;
                        line-height: 45px;
                        border-radius: 5px;
                    }
                }
            }
            .onsale{
                font-size: 13px;
                color: $whiteColor;
                font-weight: 700;
                line-height: 40px;
                background:$primaryColor;
                width: 40px;
                height: 40px;
                text-align: center;
                border-radius: 50%;
                display: inline-block;
                position: absolute;
                top: 13px;
                right: 13px;
            }

        }
        .content-desc{
            .loop-product-title{
                margin: 0;
                font-size: 18px;
                line-height: 50px;
                color: $bodyColor;
                font-weight: 700;
               a{
                   color: $titleColor;
                   font-size: 18px;
                   line-height: 52px;
                   font-weight: 600;
                    &:hover{
                        color: $primaryColor;
                    }
               }
            }
            .price{
                font-size: 20px;
                line-height: 30px;
                color: $primaryColor;
                font-weight: 700;
                margin: 0;
                del {
                    opacity: 0.6;
                    padding-right: 10px;
                }
            }
            .prev-price {
                margin-right: 10px;
                text-decoration: line-through;
                font-weight: 600;
                color: $titleColor;
            }
        }
        &:hover{
            .image-product{
                .overlay i{
                    opacity: 1;
                    visibility: visible;
                    bottom: 13px;
                }
            }
        }
    }
}
.rs-single-shop {
    .single-product-image {
        .images-single{
            z-index: 1 !important;
        }
        img {
            width: 100%;
        }
    }
    .single-price-info {
        .product-title {
            font-size: 25px;
            font-weight: 700;
            margin-bottom: 12px;
        }
        .single-price {
            color: $primaryColor;
            font-weight: 600;
        }
        .some-text {
            margin-top: 15px;
        }
        form {
            margin-bottom: 30px;
            input {
                height: 40px;
                width: 70px;
                line-height: 40px;
                text-align: center;
                padding-left: 10px;
                border: 1px solid rgba(54,54,54,.1);
                outline: none;
            }
        }
        p.category {
            margin: 0;
            padding-top: 25px;
            border-top: 1px solid #e6e6e6;
            font-size: 14px;
            span {
                font-weight: 700;
                padding-right: 10px;
            }
            a {
                color: $bodyColor;
                &:hover {
                    color: $primaryColor;
                }
            }
        }
        .tag {
            margin: 0;
            span {
                font-weight: 700;
            }
        }
    }
    .tab-area {
        margin-top: 50px;
        ul.nav-tabs {
            border-bottom: 1px solid #f0f0f0;
            margin-bottom: 32px !important;
            li {
                margin-right: 3px;
                button {
                    padding: 10px 30px;
                    display: inline-block;
                    border: none;
                    border-radius: 4px 4px 0 0;
                    font-size: 18px;
                    background: #f0f0f0;
                    color: $titleColor;
                    font-weight: 600;
                }
                &:hover,
                &.react-tabs__tab--selected {
                    button {
                        background: $primaryColor;
                        color: $whiteColor;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .tab-title {
            font-weight: 700;
            margin-bottom: 34px;
        }
        .dsc-p {
            margin: 0;
        }
        .reviews-grid {
            .top-area {
                margin-bottom: 5px;
                p {
                    margin-bottom: 23px;
                }
                h6 {
                    font-size: 15px;
                    font-weight: 600;
                    color: $bodyColor;
                }
            }
            .comment-form {
                max-width: 600px;
                p.comment-notes {
                    margin-bottom: 15px;
                }
                .placeholder-cntrl {
                    label {
                        font-weight: 600;
                        display: block;
                    }
                    input {
                        border: 1px solid rgba(54,54,54,.1);
                        padding: 10px 15px;
                        margin-bottom: 10px;
                        width: 100%;
                    }
                    textarea {
                        width: 100%;
                        padding: 10px 15px;
                        height: 75px;
                        border: 1px solid rgba(54,54,54,.1);
                        margin-bottom: 20px;
                    }
                }
                .ratings {
                    font-weight: 600;
                    margin-bottom: 18px;
                    display: block;
                    ul {
                        li {
                            display: inline;
                            i {
                                color: $primaryColor;
                                font-weight: normal;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media #{$md} {
    .rs-cart {
        .cart-wrap {
            table {
                &.cart-table {
                    .action {
                        .coupon {
                            input {
                                width: calc(100% - 204px);
                            }
                        }
                    }
                }
            }
        }
    }
}
@media #{$sm} {
    .rs-cart {
        .cart-wrap {
            table {
                &.cart-table {
                    th {
                        display: none;
                    }
                    td {
                        display: block;
                        &.action {
                            .update-cart {
                                button {
                                    width: 100%;
                                }
                            }
                            .coupon {
                                margin-bottom: 30px;
                            }
                        }
                    }
                }
            }
        }
        .cart-collaterals {
            .cart-totals {
                float: none;
                width: 100%;
            }
        }
    }
}
@media #{$xs} {
    .rs-checkout {
        .coupon-toggle {
            .accordion__item {
                .accordion__panel {
                    .card-body {
                        .coupon-code-input {
                            width: 100%;
                            float: none;
                            margin-bottom: 20px;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
    .rs-shop-part {
        .from-control {
            float: none;
        }
    }
}
@media #{$mobile} {
    .rs-checkout {
        .coupon-toggle {
            .accordion__item {
                .accordion__heading {
                    .accordion__button {
                        .card-title {
                            display: block;
                        }
                    }
                }
            }
        }
    }
    .rs-cart {
        .cart-wrap {
            table {
                &.cart-table {
                    .action {
                        .coupon {
                            margin-bottom: 25px;
                            padding-bottom: 25px;
                            border-bottom: 1px solid #e6e6e6;
                            input {
                                width: calc(100%);
                                width: 100%;
                                margin-bottom: 15px;
                                margin-right: 0 !important;
                            }
                            button {
                                width: 100%;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
        .cart-collaterals {
            .cart-totals {
                .wc-proceed-to-checkout {
                    button {
                        width: 100%;
                    }
                }
            }
        }
    }
}