.appointment-style {
    padding: 25px 40px 50px;
    border-radius: 10px;
    .form-title {
        text-align: center;
        margin-bottom: 25px;
        .title {
            margin-bottom: 0;
            color: $titleColor2;
            font-size: 24px;
            line-height: 40px;
        }
        .desc {
            margin: 0;
        }
    }
    .appointment-form {
        input {
            padding: 14px 16px 14px 16px;
            background: #F6F7F9;
            color: #454545;
            width: 100%;
            border-radius: 5px;
            border: none;
            margin-bottom: 15px;
        }
        button {
            box-shadow: 0 28px 50px 0 rgba(0,0,0,0.05);
            outline: 0;
            border: none;
            padding: 18px 18px 18px 18px;
            margin-top: 10px;
            border-radius: 3px;
            display: inline-block;
            text-transform: capitalize;
            font-size: 16px;
            font-weight: 500;
            color: $whiteColor;
            background-image: linear-gradient(160deg, $secondaryColor 0%, $primaryColor 77%);
            transition: all .3s ease;
            position: relative;
            overflow: hidden;
            width: 100%;
            cursor: pointer;
            &:hover {
                opacity: 0.9;
            }
        }
    }
}

@media #{$mobile} {
    .appointment-style {
        padding-left: 15px;
        padding-right: 15px;
    }
    .rs-contact .contact-wrap .content-part .title {
        font-size: 20px;
    }
    .rs-contact .contact-wrap .content-part .desc {
        font-size: 14px;
    }
}
