.scrollup {    
    text-align: center;
    bottom: 40px;
    cursor: pointer;
    position: fixed;
    right: 20px;
    z-index: 999;
    border-radius: 50px 50px 4px 4px;
    i {
        display: block;
        background-image: linear-gradient(90deg, #03228f 0%, #0e73e4 100%);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        line-height: 40px;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        transition: $transition;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
        &:hover {
            opacity: 0.8;            
        }
    }
    &.oranage{
        i{
            background-image: linear-gradient(180deg, #dd4c23 19%, #f27c1e 100%);
        }
    }
    &.purple-color{
        i{
           background: #787cf2; 
        }
    }
}